<template >
  <div class="app-container">
    <div
      class="item"
      style="border-bottom: 1px solid #e2e2e2; padding-bottom: 15px"
    >
      <div class="item-label">模具编号</div>
      <div class="item-content">{{ dataItem.mouldCode }}</div>
    </div>
    <div class="item">
      <div class="item-label">模具名称</div>
      <div class="item-content">{{ dataItem.mouldNameCn }}</div>
    </div>
    <div class="item">
      <div class="item-label">模具数量</div>
      <div class="item-content">{{ dataItem.amount }}</div>
    </div>
    <!-- <div class="item">
      <div class="item-label">模具寿命</div>
      <div class="item-content">{{ dataItem.mouldLife }}</div>
    </div> -->
    <div class="tags">
      <div
        v-for="item in tagList"
        :key="item.name"
        :class="{ Choose: dataItem[item.name] == '是' }"
        @click="changeStatus(item.name)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>
<script>
import { updateCheckItem } from "@/api/templateInfo/templateCheckZz";
export default {
  props: {
    dataItem: {
      type: Object,
      // required: true,
      default: {},
    },
    type: {
      type: String,
      default: "1",
    },

    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tagList: [
        { name: "sign", label: "标识" },
        { name: "workFace", label: "工作面" },
        { name: "partComplete", label: "部件完整" },
        { name: "vulnerablePart", label: "易损件" },
        { name: "other", label: "其他" },
        { name: "rust", label: "锈蚀" },
        { name: "damage", label: "损坏" },
        { name: "lose", label: "丢失" },
      ],
    };
  },
  methods: {
    changeStatus(name) {
      if (!this.isEdit) {
        return false;
      }
      this.dataItem[name] = "是";
      if (
        (name == "rust" || name == "damage" || name == "lose") &&
        (this.dataItem.rust == "是" ||
          this.dataItem.damage == "是" ||
          this.dataItem.lose == "是")
      ) {
        this.dataItem.rust = "否";
        this.dataItem.damage = "否";
        this.dataItem.lose = "否";
        this.dataItem.workFace = "否";
        this.dataItem.sign = "否";
        this.dataItem.partComplete = "否";
        this.dataItem.vulnerablePart = "否";
        this.dataItem.other = "否";
        this.dataItem[name] = "是";
      }
      if (
        (name == "workFace" ||
          name == "sign" ||
          name == "partComplete" ||
          name == "vulnerablePart" ||
          name == "other") &&
        (this.dataItem.workFace == "是" ||
          this.dataItem.sign == "是" ||
          this.dataItem.partComplete == "是" ||
          this.dataItem.vulnerablePart == "是" ||
          this.dataItem.other == "是")
      ) {
        this.dataItem.rust = "否";
        this.dataItem.damage = "否";
        this.dataItem.lose = "否";
        this.dataItem.workFace = "是";
        this.dataItem.sign = "是";
        this.dataItem.partComplete = "是";
        this.dataItem.vulnerablePart = "是";
        this.dataItem.other = "是";
      }
      let updateData = JSON.parse(JSON.stringify(this.dataItem));
      if (
        this.dataItem.rust == "是" ||
        this.dataItem.damage == "是" ||
        this.dataItem.lose == "是"
      ) {
        updateData.stateId = 1
      }else{
        updateData.stateId = 0
      }
       updateCheckItem(updateData).then((res) => {
        this.$emit("changeState");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.app-container {
  width: calc(100% - 2.5rem);
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item-label {
      width: 60%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
.tags {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  div {
    padding: 10px;
    line-height: 10px;
    text-align: center;
    background: #bababa;
    color: #ffffff;
    margin-top: 10px;
    border-radius: 5px;
  }
  .Choose {
    background: #00529c;
  }
}
</style>