import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';

// 获取自制模具点检列表
export function getMouldListZZ(data){
    return request('/mouldToolingCheck/getMouldList.do',"POST",{userId:SystemUtils.loginUser.id})
}

//获取自制模具点检详细信息
export function getMouldInfo(data){
    return request('/mouldToolingCheck/getMouldInfo.do',"POST",data)
}

//模具自制点检上传图片
export function uploadImage(data){
    return request('/mouldToolingCheck/uploadImage.do',"POST",data)
}

//模具自制点检删除图片
export function deleteImage(data){
    return request('/mouldToolingCheck/deleteImage.do',"POST",data)
}
//更新自制模具点检
export function updateCheckItem(data){
    return request('/mouldToolingCheck/updateCheckItem.do',"POST",data)
}
//发送自制模具点检
export function sendTask(data){
    return request('/mouldToolingCheck/sendTask.do',"POST",Object.assign({userId:SystemUtils.loginUser.id},data))
}
//导出自制模具点检
export function exportCheckItem(data){
    return request('/mouldToolingCheck/exportCheckItem.do',"POST",data)
}